<template>
  <div>
   <dx-util-popup
      ref="CatalogItemRef"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      width="90vw"
      height="80vh"
      title="Catalog Item Details"
      class="max-width-800"
      @hidden="onHidden"
    >
      <template #content>
        <div>
          <div class="d-flex pb-1 justify-content-between align-items-start">
            <div>
              <!-- <img :src="product.imageUrl" class="img-fluid rounded text-white limit-size">
              <div class="m-1 font-weight-bold">
                {{ product.title }}
              </div> -->
              <b class="text-warning">
              {{ productTitle }}
            </b><br>
            </div>
          </div>
          <dx-util-tab-panel v-if="true" height="85%" :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true">
            <dx-util-panel-item title="Summary" icon="bi bi-info-square">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <b-table :items="summaryData" :fields="summaryFields" />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Images" icon="bi bi-images">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="d-flex flex-wrap justify-content-center align-items-center">
                    <img
                      v-for="(image, i) in images" :key="i"
                      :src="image.url"
                      class="img-fluid rounded text-white square m-1"
                      @click="showImage(image.url)"
                    >
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Dimensions" icon="bi bi-rulers">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <b-table :items="dimensionsData" :fields="dimensionFields">
                      <template #cell(value)="{ item }">
                        <span class="text-light font-weight-bold">
                          {{ item.value }}
                        </span>
                      </template>
                      <template #cell(title)="{ item }">
                        <span class="text-capitalize">
                          {{ item.title }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Rankings" icon="bi bi-graph-up-arrow">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <b-table :items="rankingData" :fields="rankingFields">
                      <template #cell(value)="{ item }">
                        <span class="text-light font-weight-bold">
                          {{ item.value }}
                        </span>
                      </template>
                      <template #cell(title)="{ item }">
                        <span class="text-capitalize">
                          {{ item.title }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
          </dx-util-tab-panel>
          <div class="col-md-12 px-0">
            <light-box
              :items="fullImages"
              :index="index"
              :effect="'fade'"
              @close="index = null"
            />
          </div>
        </div>
      </template>
    </dx-util-popup>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import maintenanceService from '@/http/requests/common/maintenanceService'
import { Notify } from '@/@robustshell/utils'

export default {
  components: {
    'light-box': CoolLightBox,

  },
  props: {
    asin: {
      type: String || null,
      default: null,

    },
  },
  data() {
    return {
      product: {},
      catalogItem: {},
      index: null,
      isVisible: true,
      defaultVisible: false,
      rerenderKey: 0,
      fullImages: [],
      summaryFields: [
        {
          key: 'title', label: 'ATTRIBUTE', sortable: false, class: 'text-left', thStyle: { width: '30%' },
        },
        {
          key: 'value', label: 'DESCRIPTION', sortable: false, class: 'text-left', thStyle: { width: '70%' },
        },
      ],
      dimensionFields: [
        {
          key: 'title', label: 'ATTRIBUTE', sortable: false, class: 'text-left', thStyle: { width: '20%' },
        },
        {
          key: 'value', label: 'DESCRIPTION', sortable: false, class: 'text-left', thStyle: { width: '80%' },
        },
      ],
      rankingFields: [
        {
          key: 'title', label: 'RANKING TITLE', sortable: false, class: 'text-left', thStyle: { width: '40%' },
        },
        {
          key: 'rank', label: 'RANK', sortable: false, class: 'text-left', thStyle: { width: '60%' },
        },
      ],
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    summaryData() {
      const combinedData = [
        { title: 'ASIN', value: this.catalogItem.asin },
      ]
      if (this.catalogItem !== null
          && this.catalogItem.identifiers !== null
          && this.catalogItem.identifiers.length > 0
          && this.catalogItem.identifiers[0] !== null
          && this.catalogItem.identifiers[0].identifier !== null) {
        const identifiers = this.catalogItem.identifiers[0].identifiers.map(item => ({ title: item.identifierType, value: item.identifier }))

        //   // identifiers = identifiers.filter(item => item.title !== 'UPC')
        combinedData.push(...identifiers)
      }
      // // combinedData.push(
      // //   { title: 'Plan', value: this.planName },
      // //   { title: 'Pack Type', value: this.packType },
      // //   { title: 'Hazardous (Hazmat)', value: this.isHazmat },
      // // )
      const productTypes = this.catalogItem.productTypes.map(item => item.productType).toString()
      combinedData.push({ title: 'Product Types', value: productTypes })
      if (this.catalogItem !== null
          && this.catalogItem.summaries !== null
          && this.catalogItem.summaries.length > 0) {
        const content = this.catalogItem.summaries[0]
        combinedData.push(
          { title: 'Brand', value: content.brand },
          { title: 'Color', value: content.color },
          { title: 'Manufacturer', value: content.manufacturer },
          { title: 'Model Number', value: content.modelNumber },
          { title: 'Size', value: content.size },
          { title: 'Style', value: content.style },
          { title: 'Part Number', value: content.partNumber },
        )
      }
      return combinedData
    },
    productTitle() {
      return this.catalogItem?.summaries[0]?.itemName
    },
    images() {
      let combinedData = []
      if (this.catalogItem !== null
          && this.catalogItem.images !== null
          && this.catalogItem.images.length > 0
          && this.catalogItem.images[0] !== null
          && this.catalogItem.images[0].images !== null
          && this.catalogItem.images[0].images.length > 0) {
        combinedData = this.catalogItem.images[0].images.map(item => ({ url: item.link }))
      }
      return combinedData
    },
    dimensionsData() {
      const combinedData = []
      if (this.catalogItem !== null
            && this.catalogItem.dimensions !== null
            && this.catalogItem.dimensions.length > 0) {
        let dimensions = Object.keys(this.catalogItem.dimensions[0].package)
          .map(key => ({ title: key, value: this.catalogItem.dimensions[0].package[key] }))
        dimensions = dimensions.map(item => ({ title: item.title, value: `${item.value.value.toFixed(2)} ${item.value.unit}` }))
        combinedData.push(...dimensions)
      }
      return combinedData
    },
    rankingData() {
      const combinedData = []
      if (this.catalogItem !== null && this.catalogItem.salesRanks !== null
          && this.catalogItem.salesRanks.length > 0
          && this.catalogItem.salesRanks[0] !== null
          && this.catalogItem.salesRanks[0].classificationRanks !== null
          && this.catalogItem.salesRanks[0].classificationRanks.length > 0) {
        const classificationRank = this.catalogItem.salesRanks[0].classificationRanks.map(item => ({ title: item.title, rank: item.rank }))
        combinedData.push(...classificationRank)
      }
      if (this.catalogItem !== null && this.catalogItem.salesRanks !== null
          && this.catalogItem.salesRanks.length > 0
          && this.catalogItem.salesRanks[0] !== null
          && this.catalogItem.salesRanks[0].displayGroupRanks !== null
          && this.catalogItem.salesRanks[0].displayGroupRanks.length > 0) {
        const displayGroupRank = this.catalogItem.salesRanks[0].displayGroupRanks.map(item => ({ title: item.title, rank: item.rank }))
        combinedData.push(...displayGroupRank)
      }
      return combinedData
    },
  },
  watch: {
    asin: {
      handler() {
        if (this.asin !== null) {
          this.getCatalogItemByAsin()
        }
      },
    },
  },
  methods: {
    getCatalogItemByAsin(e) {
      maintenanceService.getCatalogItemsByAsin(this.asin).then(res => {
        this.catalogItem = res.data.body
        const popup = this.$refs.CatalogItemRef.instance
        popup.show()
      }).catch(error => {
        Notify.error(`Product fetch by ASIN failed: ${error}`)
      })
    },
    showImage(imageUrl) {
      this.fullImages.length = 0
      this.index = 0
      this.fullImages.push(imageUrl)
    },
    onHidden() {
      const popup = this.$refs.CatalogItemRef.instance
      popup.hide()
      this.$emit('on-emit-close')
    },
    openPlanPopup() {
      this.isVisible = true
    },
  },

}
</script>

<style lang="scss">
.limit-size {
  max-width: 100px;
  height: auto;
}
.square {
  width: 100px;
  height: 100px;
}
.dx-tab {
  border-radius: 3px 3px 0 0;
}
</style>
